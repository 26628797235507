<template>
  <div class="font-montserrat d-flex flex-column choose-role-content">
    <!-- form -->
    <validation-observer #default="{ invalid }">
      <b-form @submit.prevent="onSubmitDetails">
        <validation-provider
          #default="{ errors }"
          name="First Name"
          vid="first-name"
          rules="required|min:3"
        >
          <b-form-group
            class="custom-input-group mrg-bottom-16"
            :class="
              isDirty.firstName
                ? errors.length > 0
                  ? 'invalid-input-group'
                  : 'valid-input-group'
                : ''
            "
          >
            <b-form-input
              v-model="signupDetails.firstName"
              class="custom-input"
              type="text"
              placeholder="First Name"
            />
            <span
              class="custom-input-placeholder text-capitalize font-montserrat"
              data-content="First Name"
            >
              First Name
            </span>
            <validation-icon-with-tooltip
              id="first-name-validation"
              class="kp-validation-icon"
              :show="isDirty.firstName"
              :errors="errors"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="Last Name"
          vid="last-name"
          rules="required|min:1"
        >
          <b-form-group
            class="custom-input-group mrg-bottom-16"
            :class="
              isDirty.lastName
                ? errors.length > 0
                  ? 'invalid-input-group'
                  : 'valid-input-group'
                : ''
            "
          >
            <b-form-input
              v-model="signupDetails.lastName"
              class="custom-input"
              type="text"
              placeholder="Last Name"
            />
            <span
              class="custom-input-placeholder text-capitalize font-montserrat"
              data-content="Last Name"
            >
              Last Name
            </span>
            <validation-icon-with-tooltip
              id="last-name-validation"
              class="kp-validation-icon"
              :show="isDirty.lastName"
              :errors="errors"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="Country"
          vid="country"
          rules="required"
        >
          <b-form-group
            class="custom-input-group mrg-bottom-16"
            :class="
              isDirty.country
                ? errors.length > 0
                  ? 'invalid-input-group'
                  : 'valid-input-group'
                : ''
            "
          >
            <v-select
              v-model="signupDetails.country"
              class="font-montserrat"
              :options="countries"
              placeholder="Country"
            />
            <span
              class="custom-input-placeholder text-capitalize font-montserrat"
              data-content="Country"
            >
              Country
            </span>
            <validation-icon-with-tooltip
              id="country-validation"
              class="kp-validation-icon"
              :show="errors.length > 0"
              :errors="errors"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="Phone Number"
          vid="phone-number"
          rules="required|phNo"
        >
          <b-form-group
            class="custom-input-group sipnup-tel-input mrg-bottom-16"
            :class="
              isDirty.phone
                ? errors.length > 0 || isNotValidPhoneNumber
                  ? 'invalid-input-group'
                  : 'valid-input-group'
                : ''
            "
          >
            <vue-tel-input
              :value="signupDetails.phone ? fullPhoneNumber : ''"
              :input-options="inputOptions"
              class="custom-input font-montserrat"
              mode="international"
              name="phone"
              @input="onInputPhoneNumber"
            />
            <span
              v-show="isDirty.phone"
              class="custom-input-placeholder text-capitalize font-montserrat"
              data-content="Phone Number"
            >
              Phone Number
            </span>
            <validation-icon-with-tooltip
              id="phone-number-validation"
              class="kp-validation-icon"
              :show="isDirty.phone"
              :errors="isNotValidPhoneNumber ? ['Invalid phone number'] : errors"
            />
          </b-form-group>
        </validation-provider>
        <!-- Field only enabled for referral -->
        <b-form-group
          v-if="signupDetails.referrerCode"
          class="custom-input-group mrg-bottom-16"
        >
          <b-form-input
            :value="signupDetails.referrerCode"
            class="custom-input"
            type="text"
            placeholder="Referrer Code"
            disabled
          />
        </b-form-group>
        <b-button
          type="submit"
          class="font-inter custom-btn-submit mrg-bottom-16"
          variant="info"
          block
          :disabled="invalid || isNotValidPhoneNumber"
        >
          <span> Next Step </span>
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import {
 BButton, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, phNo } from '@validations'
import ValidationIconWithTooltip from '@/@core/components/icon-with-tooltip-error/ValidationIconWithTooltip.vue'
import { AUTH_REGISTER_CHILDREN } from '@/router/routes/pages'
import vSelect from 'vue-select'
import { mapState } from 'vuex'
import { GET_COUNTRIES } from '@/store/modules/common.module'

export default {
  name: 'SetPassword',
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    ValidationIconWithTooltip,
    vSelect,
  },
  props: {
    routeQueryParams: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      countries: [],
      emailEdited: false,
      // For Validation
      isDirty: {
        firstName: false,
        lastName: false,
        country: false,
        phone: false,
      },
      required,
      phNo,
      isValidPhoneNumber: false,
      inputOptions: {
        id: 'signup_phone_number',
        placeholder: 'Enter phone number',
      },
    }
  },
  computed: {
    ...mapState({
      signupDetails: state => state.signup.signupDetails,
    }),
    isNotValidPhoneNumber() {
      return this.signupDetails?.phone && !this.isValidPhoneNumber
    },
    fullPhoneNumber() {
      return `${this.signupDetails?.countryCode} ${this.signupDetails?.phone}`
    },
  },
  watch: {
    signupDetails: {
      handler(val) {
        if (val.firstName) {
          this.isDirty.firstName = true
        }
        if (val.lastName) {
          this.isDirty.lastName = true
        }
        if (val.country) {
          this.isDirty.country = true
        }
        if (val.phone) {
          this.isDirty.phone = true
        }
      },
      deep: true,
    },
  },
  created() {
    this.getCommonData()
    if (this.routeQueryParams.referrerCode) {
      this.signupDetails.referrerCode = this.routeQueryParams.referrerCode
    }
  },
  methods: {
    onInputPhoneNumber(number, phoneObject) {
      this.isValidPhoneNumber = !!phoneObject?.valid
      this.signupDetails.phone = number && phoneObject.nationalNumber
      this.signupDetails.countryCode = `+${phoneObject.countryCallingCode}`
    },
    getCommonData() {
      this.$store.dispatch(GET_COUNTRIES).then(res => {
        this.countries = res?.data?.data?.COUNTRIES || []
      })
    },
    onSubmitDetails() {
      this.$router.push({ name: AUTH_REGISTER_CHILDREN.SET_PASSWORD })
    },
  },
}
</script>

<style lang="scss" >
@import '~@core/scss/vue/pages/signup/kp-signup.scss';
@import '~@core/scss/vue/libs/vue-select.scss';

.vue-tel-input {
  display: flex !important;
  padding-left: 0px !important;
}
</style>
