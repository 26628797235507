var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-montserrat d-flex flex-column choose-role-content"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitDetails($event)}}},[_c('validation-provider',{attrs:{"name":"First Name","vid":"first-name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"custom-input-group mrg-bottom-16",class:_vm.isDirty.firstName
              ? errors.length > 0
                ? 'invalid-input-group'
                : 'valid-input-group'
              : ''},[_c('b-form-input',{staticClass:"custom-input",attrs:{"type":"text","placeholder":"First Name"},model:{value:(_vm.signupDetails.firstName),callback:function ($$v) {_vm.$set(_vm.signupDetails, "firstName", $$v)},expression:"signupDetails.firstName"}}),_c('span',{staticClass:"custom-input-placeholder text-capitalize font-montserrat",attrs:{"data-content":"First Name"}},[_vm._v(" First Name ")]),_c('validation-icon-with-tooltip',{staticClass:"kp-validation-icon",attrs:{"id":"first-name-validation","show":_vm.isDirty.firstName,"errors":errors}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Last Name","vid":"last-name","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{staticClass:"custom-input-group mrg-bottom-16",class:_vm.isDirty.lastName
              ? errors.length > 0
                ? 'invalid-input-group'
                : 'valid-input-group'
              : ''},[_c('b-form-input',{staticClass:"custom-input",attrs:{"type":"text","placeholder":"Last Name"},model:{value:(_vm.signupDetails.lastName),callback:function ($$v) {_vm.$set(_vm.signupDetails, "lastName", $$v)},expression:"signupDetails.lastName"}}),_c('span',{staticClass:"custom-input-placeholder text-capitalize font-montserrat",attrs:{"data-content":"Last Name"}},[_vm._v(" Last Name ")]),_c('validation-icon-with-tooltip',{staticClass:"kp-validation-icon",attrs:{"id":"last-name-validation","show":_vm.isDirty.lastName,"errors":errors}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Country","vid":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{staticClass:"custom-input-group mrg-bottom-16",class:_vm.isDirty.country
              ? errors.length > 0
                ? 'invalid-input-group'
                : 'valid-input-group'
              : ''},[_c('v-select',{staticClass:"font-montserrat",attrs:{"options":_vm.countries,"placeholder":"Country"},model:{value:(_vm.signupDetails.country),callback:function ($$v) {_vm.$set(_vm.signupDetails, "country", $$v)},expression:"signupDetails.country"}}),_c('span',{staticClass:"custom-input-placeholder text-capitalize font-montserrat",attrs:{"data-content":"Country"}},[_vm._v(" Country ")]),_c('validation-icon-with-tooltip',{staticClass:"kp-validation-icon",attrs:{"id":"country-validation","show":errors.length > 0,"errors":errors}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Phone Number","vid":"phone-number","rules":"required|phNo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{staticClass:"custom-input-group sipnup-tel-input mrg-bottom-16",class:_vm.isDirty.phone
              ? errors.length > 0 || _vm.isNotValidPhoneNumber
                ? 'invalid-input-group'
                : 'valid-input-group'
              : ''},[_c('vue-tel-input',{staticClass:"custom-input font-montserrat",attrs:{"value":_vm.signupDetails.phone ? _vm.fullPhoneNumber : '',"input-options":_vm.inputOptions,"mode":"international","name":"phone"},on:{"input":_vm.onInputPhoneNumber}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDirty.phone),expression:"isDirty.phone"}],staticClass:"custom-input-placeholder text-capitalize font-montserrat",attrs:{"data-content":"Phone Number"}},[_vm._v(" Phone Number ")]),_c('validation-icon-with-tooltip',{staticClass:"kp-validation-icon",attrs:{"id":"phone-number-validation","show":_vm.isDirty.phone,"errors":_vm.isNotValidPhoneNumber ? ['Invalid phone number'] : errors}})],1)]}}],null,true)}),(_vm.signupDetails.referrerCode)?_c('b-form-group',{staticClass:"custom-input-group mrg-bottom-16"},[_c('b-form-input',{staticClass:"custom-input",attrs:{"value":_vm.signupDetails.referrerCode,"type":"text","placeholder":"Referrer Code","disabled":""}})],1):_vm._e(),_c('b-button',{staticClass:"font-inter custom-btn-submit mrg-bottom-16",attrs:{"type":"submit","variant":"info","block":"","disabled":invalid || _vm.isNotValidPhoneNumber}},[_c('span',[_vm._v(" Next Step ")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }